import React from "react";
import "./Items.css";
import { Link } from 'react-router-dom';

function Items(props) {
  const { logo, appName, tags, title, text, goto } = props;
  
  return (
    <>
      <div className="item">
        <img src={`${logo}`} alt={title} />
        <h2>{appName}</h2>
        
        <div className="item__tags">
          {
            tags.map((tag, index) => (
              <span key={index} className="tags">
                <i>{tag}</i>
              </span>
            ))
          }
        </div>
        
        <span className="Main_text">{text}</span>    

        {goto.length > 0 && (
          <div className="pt-2">
            <Link className="btn  btn-primary w-100" to={`/${goto}`}>View Project</Link>  
          </div>
        )}
      </div>
    </>
  );
}

export default Items;
