import React from 'react';
import './Airbnb.css';
import { Link } from 'react-router-dom';
import Survey_01 from '../../src/images/airbnb/survey_01.png';
import Persona from '../../src/images/airbnb/persona.png';
import Userflow_1 from '../../src/images/airbnb/userflow_1.png';
import Userflow_2 from '../../src/images/airbnb/userflow_2.png';
import Userflow_3 from '../../src/images/airbnb/userflow_3.png';
import Age_demo from '../../src/images/airbnb/demo.png';
import Gender from '../../src/images/airbnb/gender.png';
import Complaints from '../../src/images/airbnb/complaints.png';
import Common_Complaints from '../../src/images/airbnb/comon_complaints.png';
import Prob_Solution from '../../src/images/airbnb/prob_solution.png';

import StoreBanner from '../../src/images/airbnb/airbnb-banner.jpg';
import ProfilePic from '../../src/images/me.jpg';

import Iframe from 'react-iframe'

function StoreLocator() {
    return (
        <>        
            <div id="about" className="airbnb">      

                <div className="navbar">
                    <div className="container">
                        <Link to="/">
                            <img src={ProfilePic} alt=""/>
                            Wayne Hewitt
                        </Link>
                    </div>
                </div>

                <div className="page__banner"  style={{ backgroundImage: `url(${StoreBanner})`}}>
                    <h1 className="banner__title">Airbnb True-to-Listing Program <br />
                    <span className='text-sm'>Building trust through user-verified listings and AI-powered accuracy.</span></h1>
                    <h3 className="banner__title">User Experience Design,
                        UX Strategy,
                        Interface Design,
                        User Flows,
                        User Research</h3>
                </div>

                <div className="container flex--2">
                    <div>
                        <h3>The Product</h3>
                        <h4>Airbnb True-to-Listing Program</h4><br />
                        <p>A system designed to enhance trust and transparency on Airbnb by allowing guests to verify property listings through user-submitted photos. This initiative bridges the gap between user expectations and the reality of stays, ensuring a more reliable booking experience.</p></div>
                    <div>
                        <h3>The problem</h3>
                        <h4>Misleading Listings Erode Trust</h4><br />
                        <p>Airbnb users often encounter discrepancies between property photos and actual conditions, leading to dissatisfaction, negative reviews, and trust erosion. Without a clear verification process, users struggle to confidently book properties that meet their expectations.</p></div>
                </div>
                <span className="spacer"></span>
                <div className="container" >
                    <h3>The Project</h3>
                    <h4>Designing a Trust-Building Solution</h4><br />
                    <p>I created the True-to-Listing Program, focusing on user research, UX strategy, and AI integration.</p>
                    <p>The project included:</p>
                    <ul>
                        <li>Conducting surveys to understand user pain points and motivations.</li>
                        <li>Developing personas to guide the design process.</li>
                        <li>Creating detailed user flows and wireframes for the verification system.</li>
                        <li>Designing an AI-powered comparison tool to validate guest-submitted photos against host listings.</li>
                        <li>Introducing a rewards system to encourage user participation. This solution aims to improve listing accuracy, enhance user confidence, and foster community transparency.</li>
                    </ul>
                </div>

                <span className="spacer"></span>
                <div className="container--sm">
                    <div>
                        <h3>Research and Survey Insights</h3>
                        <h4>Understanding User Needs: Research and Insights</h4><br />
                        <p>
                            Research Approach<br />
                            Goal: Identify Airbnb user pain points, particularly regarding trust and transparency in listings.
                          
                        </p>  
                            <ul>
                                <li>Conducted surveys targeting Airbnb users.</li>
                                <li>Analyzed complaints and demographic trends to shape design decisions.</li>
                            </ul>
                    </div>
                </div>
                <span className="spacer"></span>
                <div className="container flex--2">
                    <div>
                        <img src={Survey_01} alt={Survey_01}></img>
                    </div>
                    <div>
                        <h3>Survey Insights</h3>
                        <h4>Questions Asked:</h4><br />
                        <ul className='p-0'>
                            <ol className='m-0'><b>How important is trust when booking an Airbnb?</b><br /><br />
                            Insights: Over 85% of users rated trust as "Very Important" or "Extremely Important."</ol><br />
                            <ol><b>What motivates trust in a listing?</b><br /><br />
                            Insights: Clear visuals, honest reviews, and transparent descriptions topped responses.</ol><br />
                            <ol><b>Would you participate in verifying listings?</b><br /><br />
                            Insights: 65% responded "Yes" or "Maybe."</ol><br />
                            <ol><b>Preferred rewards for participation:</b><br /><br />
                            Insights: Discounts and loyalty points were most popular.</ol><br />
                            <ol><b>Confidence in listings:</b><br /><br />
                            Insights: 50% of users expressed only "Neutral" or "Slight Confidence" in listing accuracy.</ol>
                        </ul>
                    </div>
                </div>
                <span className="spacer"></span>
                <div className="container flex--2">
                    <div>
                        <h3>Age Demographic</h3>
                        <p>Ages 25–34: 36% of Airbnb users, the largest group </p>
                    </div>
                    <div>
                        <img src={Age_demo} alt={Age_demo}></img>
                    </div>
                </div>
                <span className="spacer"></span>
                <div className="container flex--2">
                    <div>
                        <img src={Gender} alt={Gender}></img>
                    </div>
                    <div>
                        <h3>Gender</h3>
                        <p>Slightly more women (54%) than men (46%) use Airbnb</p>
                    </div>
                    
                </div>

                <span className="spacer"></span>
                <div className="container flex--2">
                    <div>
                        <h3>Airbnb's User Travel Behaviour</h3> 
                        <ul>
                            <li>Younger users (25–34) often seek affordability and unique experiences.</li>
                            <li>Families represent a growing segment, with a 60% increase in bookings
                            compared to pre-pandemic levels.</li>
                        </ul>
                    </div>
                    <div>
                        <h3>Airbnb's User Motivations</h3> 
                        <ul>
                            <li>53% choose Airbnb for cost savings.</li>
                            <li>33% prefer the authentic experience of living like a local.</li>
                        </ul>
                    </div>
                </div>

                <span className="spacer"></span>
                <div className="container">
                    <div>
                        <h3>Complaints 2023</h3>                         
                        <img src={Complaints} alt={Complaints}></img>
                    </div>
                </div>
                <span className="spacer"></span>
                <div className="container">
                    <div>
                        <h3>Common Complaints</h3>                         
                        <img src={Common_Complaints } alt={Common_Complaints }></img>
                    </div>
                </div>
                <span className="spacer"></span>
                <div className="container">
                    <div>
                        <h3>Problem and Proposed Solution</h3>                         
                        <img src={Prob_Solution } alt={Prob_Solution }></img>
                    </div>
                </div>
                <span className="spacer"></span>
                <div className="container--sm">
                    <div>
                        <h3>How AI Ensures Listing Accuracy</h3> 
                        <ul>
                            <li>AI compares guest-uploaded photos with the host’s original images.</li>
                            <li>Checks key attributes like furniture placement, layout, decor, and lighting.</li>
                            <li>Flags discrepancies for review to ensure listings reflect reality.</li>
                            <li>Builds trust by maintaining transparency and accountability.</li>
                        </ul>
                    </div>
                </div>

                <span className="spacer"></span>
                <div className="container">
                    <div>
                        <h3>Persona</h3>                         
                        <img src={Persona} alt={Persona}></img>
                    </div>
                </div>

                <span className="spacer"></span>
                <div className="container">
                    <div>
                        <h3>User Flows Section</h3> 
                        
                        <p>The True-to-Listing Program follows a straightforward process designed to encourage users to verify Airbnb listings by uploading photos of their stay. The flow begins with a notification inviting users to participate, followed by a guided photo upload process and a final AI verification step. The goal is to ensure that the listings on Airbnb accurately reflect reality, improving trust and transparency within the community.</p>

                        <p>Steps in the Basic Flow:</p>

                        <ul>
                            <li>User checks into the property.</li>
                            <li>Receives a notification inviting them to verify the listing.</li>
                            <li>User agrees to participate and uploads photos.</li>
                            <li>AI compares user-submitted photos with host-provided images.</li>
                            <li>Discrepancies are flagged, and the host is notified if necessary.</li>
                            <li>User receives a thank-you message and a reward for their participation.</li>
                        </ul>
                        <span className="spacer"></span>
                        <img src={Userflow_1} alt={Userflow_1}></img>
                    </div>
                </div>

                <span className="spacer"></span>
                <div className="container">
                    <div>
                        <h3>User Flow</h3> 
                        
                        <p>The user flow focuses on the guest's journey through the True-to-Listing Program. It ensures a seamless experience, from the initial invitation to submit photos, through the upload process, and finally to receiving their reward. Each step is designed to minimize friction and maximize user engagement.</p>

                        <p>Key Steps in the User Flow:</p>

                        <ul>
                            <li>Invitation to participate (via notification).</li>
                            <li>Agree to terms and conditions.</li>
                            <li>Guided photo upload (bedroom, kitchen, bathroom, etc.).</li>
                            <li>Submission confirmation and thank-you message.</li>
                            <li>Reward redemption.</li>
                        </ul>
                        <span className="spacer"></span>
                        <img src={Userflow_2} alt={Userflow_2}></img>   
                    </div>
                </div>
                

                <span className="spacer"></span>
                <div className="container">
                    <div>
                        <h3>Host User Flow</h3> 
                        
                        <p>The host user flow addresses what happens when a listing is flagged for discrepancies. It ensures that hosts are notified promptly and given clear steps to rectify any issues to maintain listing accuracy.</p>

                        <p>Key Steps in the Host User Flow:</p>

                        <ul>
                            <li>AI flags discrepancies between guest and host photos.</li>
                            <li>Host receives a notification about the flagged listing.</li>
                            <li>Host reviews the flagged photos and adjusts the listing if necessary.</li>
                            <li>The listing is re-verified to ensure compliance.</li>
                            <li>Host receives confirmation once the listing is updated.</li>
                        </ul>
                        <span className="spacer"></span>
                        <img src={Userflow_3} alt={Userflow_3}></img>
                    </div>
                </div>

                <span className="spacer"></span>
                <div className="container">
                    <div>
                        <h3>Low-Fidelity to High-Fidelity Progression</h3> 
                        
                        <h4>Design Progression</h4><br />
                        <p>The design process began with low-fidelity wireframes to map out the core user flows and interactions. These initial sketches helped validate the concept before moving to more detailed designs. Unfortunately, due to a technical issue with my Google Drive, the original low-fidelity images were corrupted and cannot be displayed here.</p>
                    </div>
                </div>

                <span className="spacer"></span>
                <div className="container">
                    <div>
                        <h3>High-Fidelity Designs</h3> 
                        
                        <p>After validating the initial user flows with low-fidelity wireframes, I moved to high-fidelity designs to bring the True-to-Listing Program to life. These screens focus on creating a seamless and visually engaging user experience while maintaining Airbnb's brand identity.</p>

                        <p>The high-fidelity designs include key features such as:</p>

                        <ul>
                            <li>Terms and Conditions Screen: Ensures users understand their participation and agree to the process.</li>
                            <li>Photo Upload Guide: Provides users with clear instructions on capturing and submitting accurate photos.</li>
                            <li>AI Verification Results: Demonstrates how AI compares guest-submitted images to host-provided photos and flags discrepancies.</li>
                            <li>Thank-You Page: Acknowledges user contributions and rewards them with a gift card to encourage further engagement.</li>
                        </ul>
                    </div>
                </div>
                
                <span className="spacer"></span>
                <div className='container'>
                    <Iframe src="https://embed.figma.com/design/WkOJDpy5WtzCiPkfYvkldw/Airbnb-Mobile-App-True-to-Listing?node-id=3-35&embed-host=share"
                        width="100%"
                        height="960px"
                        id=""
                        className=""
                        display="block"
                        position="relative"/>
                </div>


                <span className="spacer"></span>
                <div className="container">
                    <div>
                        <h3>Working Prototype</h3> 
                        
                        <p>The interactive prototype, embedded directly from Figma, showcases the full user journey of the True-to-Listing Program, from onboarding to photo submission and verification. This prototype reflects the design's usability, accessibility, and overall flow, allowing users to interact with the key features of the program.</p>

                    </div>
                </div>

                <span className="spacer"></span>
                <div className='container'>
                    <Iframe  width="100%" height="960" src="https://embed.figma.com/proto/WkOJDpy5WtzCiPkfYvkldw/Airbnb-Mobile-App-True-to-Listing?page-id=3%3A35&node-id=2713-14404&p=f&viewport=65%2C9%2C0.23&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=2713%3A14404&embed-host=share" allowfullscreen/>
                </div>

                
                <span className="spacer"></span>
                <div className="container">
                    <div>
                        <h3>What I Would Improve</h3> 
                        
                        <p>While the prototype effectively demonstrates the core functionality of the solution, there are areas I would further refine, including:</p>


                        <ul>
                            <li>Onboarding Process: Streamlining the terms and conditions to ensure users can quickly understand and agree without overwhelming them.</li>
                            <li>Photo Upload Experience: Enhancing the guidance provided during the photo upload process to reduce confusion and increase user engagement.</li>
                            <li>AI Feedback: Providing users with more actionable feedback from the AI analysis, such as specific details on flagged discrepancies.</li>
                        </ul>
                    </div>
                </div>
                
                <span className="spacer"></span>
                <div className="container">
                    <div>
                        <h3>What I Would Do Next</h3> 
                        
                        <p>As a next step, I would conduct usability testing to gather real user feedback on the prototype. This would help identify any pain points or areas for improvement that may not have been apparent during the design process. I would also explore adding features like:</p>

                        <ul>
                            <li>Gamification Elements: To further encourage user participation, such as progress tracking and badges.</li>
                            <li>Localization: Ensuring the interface and instructions are accessible in multiple languages to cater to a global audience.</li>                        
                        </ul>
                    </div>
                </div>
                
                <span className="spacer"></span>
                <div className="container">
                    <div>
                        <h3>Afterthoughts on UX</h3> 
                        
                        <p>This project highlighted the importance of building trust through transparency and user participation. One of the key takeaways was understanding how UX can be a powerful tool for enhancing user confidence in a platform. Balancing simplicity, functionality, and trustworthiness in the design was crucial, and the process reaffirmed that successful UX design requires continuous iteration based on user feedback.</p>
                    </div>
                </div>
                <span className="spacer"></span>
            </div>
            
        </>
    )
}

export default StoreLocator

