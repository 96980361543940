
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import React from 'react';
import './App.css';
import Home from './pages/Home';
import StoreLocator from './pages/StoreLocator';
import Client1 from './pages/Client1';
import Airbnb from './pages/Airbnb';

import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';


function App() {

  return (
    <div className="app">
      <Router>
        <Switch>          
            <BrowserRouter>
                <ScrollToTop>
                  <Route exact path='/' component={Home} />
                  <Route path='/StoreLocator' component={StoreLocator} />
                  <Route path='/Client1' component={Client1} />
                  <Route path='/Airbnb' component={Airbnb} />
              </ScrollToTop>
            </BrowserRouter>
        </Switch>        
      </Router>
    </div>
  );
}

export default App;
